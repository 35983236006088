import React from 'react';
import { Layout, Col, Row, Image, Typography, Button, Space } from 'antd';
import { PlayCircleOutlined } from '@ant-design/icons';

import './privacy.less';
const { Content } = Layout;
const { Title, Paragraph, Text, Link } = Typography;

export default function Privacy() {
  return (
    <Content className='privacy-root'>
      <Col className='privacy-container'>
        <Col style={{ alignItems: 'center' }}>
          <Typography>
            <Title className='privacy-title'>Privacy policy</Title>
            <p>
              Welcome to FieldNext website, web console and mobile apps ("Our
              services") by DotNext Technologies Pvt. Ltd. ("us", "we", or "our").
              FieldNext's mission is making field operations of an organisation
              simpler, smarter, and more productive. Our registered users and
              organisation personel ("Users") share their professional identities,
              locations, engage with other Users and post & view their works content.
              Unregistered viewers ("Nonusers") and Users not within your company or
              team ("Organization") may not view User's content. Administrator and
              authorised Managers within the Organization may view your content.
            </p>
            <p>
              We have implemented reasonable security practices and procedures that are
              commensurate with the information assets being protected and with the
              nature of our business. While we try our best to provide security that is
              better than the industry standards, because of the inherent
              vulnerabilities of the internet, we cannot ensure or warrant complete
              security of all information that is being transmitted to us by you. By
              visiting this Website or using our services, you agree and acknowledge to
              be bound by this Privacy Policy and you hereby consent that we will
              collect, use, process and share your informations in the manner set out
              herein below. If you do not agree with these terms, do not use the
              Website and our services.
            </p>

            <h3>Types of Data collected</h3>
            <p>
              <p>
                Among the types of Personal Data that FieldNext collects, by itself or
                through third parties, there are: Usage Data; Cookies; Camera
                permission; Storage permission; Motion sensors permission; Call
                permission; Location permission; Phone permission; first name; last
                name; phone number; company name; address; country; state; email
                address; ZIP/Postal code; city; various types of Data; movement
                activity; geographic position; username; password; profession; picture;
                profile picture; billing address; house number.
              </p>
              <p>
                Unless specified otherwise, all Data requested by FieldNext is mandatory
                and failure to provide this Data may make it impossible for FieldNext to
                provide its services. In cases where FieldNext specifically states that
                some Data is not mandatory, Users are free not to communicate this Data
                without consequences to the availability or the functioning of the
                Service.
                <br />
                Users who are uncertain about which Personal Data is mandatory are
                welcome to contact us by our contact detail at the bottom.
                <br />
                Any use of Cookies – or of other tracking tools – by FieldNext or by the
                owners of third-party services used by FieldNext serves the purpose of
                providing the Service required by the User, in addition to any other
                purposes described in the present document and in the Cookie Policy, if
                available.
              </p>
              <p>
                Users are responsible for any third-party Personal Data obtained,
                published or shared through FieldNext and confirm that they have the
                third party's consent to provide the Data to the FieldNext.
              </p>
            </p>
            <h3>Mode and place of processing the Data</h3>
            <p>
              <p>
                <h4>Methods of processing</h4>
                The FieldNext takes appropriate security measures to prevent
                unauthorized access, disclosure, modification, or unauthorized
                destruction of the Data. The Data processing is carried out using
                computers and/or IT enabled tools, following organizational procedures
                and modes strictly related to the purposes indicated. In addition to the
                FieldNext, in some cases, the Data may be accessible to certain types of
                persons in charge, involved with the operation of FieldNext
                (administration, sales, marketing, legal, system administration) or
                external parties (such as third-party technical service providers, mail
                carriers, hosting providers, IT companies, communications agencies)
                appointed, if necessary, as Data Processors by the FieldNext. The
                updated list of these parties may be requested from the FieldNext at any
                time.
              </p>
              <p>
                <h4>Legal basis of processing</h4>
                We may process Personal Data relating to Users if one of the following
                applies:
                <ul>
                  <li>
                    Users have given their consent for one or more specific purposes.
                    Note: Under some legislations we may be allowed to process Personal
                    Data until the User objects to such processing (“opt-out”), without
                    having to rely on consent or any other of the following legal bases.
                  </li>
                  <li>
                    provision of Data is necessary for the performance of an agreement
                    with the User and/or for any pre-contractual obligations thereof;
                  </li>
                  <li>
                    processing is necessary for compliance with a legal obligation to
                    which we is subject;
                  </li>
                  <li>
                    processing is related to a task that is carried out in the public
                    interest or in the exercise of official authority vested in the
                    FieldNext;
                  </li>
                  <li>
                    processing is necessary for the purposes of the legitimate interests
                    pursued by us or by a third party.
                  </li>
                </ul>
              </p>
              <p>
                <h4>Place</h4>
                The Data is processed at our operating offices, hosted clouds and in any
                other places where the parties involved in the processing are located.
                Depending on the User's location, data transfers may involve
                transferring the User's Data to a country other than their own.
              </p>
              <p>
                <h4>Retention time</h4>
                Personal Data shall be processed and stored for as long as required by
                the purpose they have been collected for.
                <br />
                <br />
                Therefore:
                <ul>
                  <li>
                    Personal Data collected for purposes related to the performance of a
                    contract between the FieldNext and the User shall be retained until
                    such contract has been fully performed.
                  </li>
                  <li>
                    Personal Data collected for the purposes of the FieldNext’s legitimate
                    interests shall be retained as long as needed to fulfill such
                    purposes.
                  </li>
                </ul>
              </p>
            </p>
            <p>
              <h3>Mode and place of processing the Data</h3>
              <p>
                The Data concerning the User is collected to allow us to provide its
                Service, comply with its legal obligations, respond to enforcement
                requests, protect its rights and interests (or those of its Users or
                third parties), detect any malicious or fraudulent activity, as well as
                the following: Handling payments, Hosting and backend infrastructure,
                Managing contacts and sending messages, Analytics, Platform services and
                hosting, Tag Management, Device permissions for Personal Data access,
                Contacting the User, Data transfer outside the EU, Displaying content
                from external platforms, Handling activity data, Location-based
                interactions and Registration and authentication provided directly by
                FieldNext.
              </p>
            </p>
            <p>
              <h3>Device permissions for Personal Data access</h3>
              <p>
                Depending on the User's specific device, FieldNext may request certain
                permissions that allow it to access the User's device Data as described
                below.
                <br />
                <br />
                By default, these permissions must be granted by the User before the
                respective information can be accessed. Once the permission has been
                given, it can be revoked by the User at any time. In order to revoke
                these permissions, Users may refer to the device settings.
                <br />
                The exact procedure for controlling app permissions may be dependent on
                the User's device and software.
                <br />
                <br />
                Please note that the revoking of such permissions might impact the
                proper functioning of FieldNext services.
                <br />
                <br />
                If User grants any of the permissions listed below, the respective
                Personal Data may be processed (i.e accessed to, modified or removed) by
                FieldNext.
              </p>
              <h4>Camera permission</h4>
              Used for accessing the camera or capturing images and video from the
              device.
              <h4>Motion sensors permission</h4>
              Used for accessing the User's device motion sensors to measure the User's
              activity such as step counts, stairs climbed, and movement type (walking,
              cycling, etc.).
              <h4>Phone permission</h4>
              Used for accessing a host of typical features associated with telephony.
              This enables, for instance, read-only access to the “phone state”, which
              means it enables access to the phone number of the device, current mobile
              network information, or the status of any ongoing calls.
              <h4>Location permission</h4>
              Used for accessing the User's device location. FieldNext may collect,
              use, and share User location Data in order to provide location-based
              services.
              <h4>Storage permission</h4>
              Used for accessing shared external storage, including the reading and
              adding of any items.
            </p>
            <p>
              <h3>Cookie Policy</h3>
              <p>
                This document informs Users about the technologies that help FieldNext
                to achieve the purposes described below. Such technologies allow us to
                access and store information (for example by using a Cookie) or use
                resources (for example by running a script) on a User’s device as they
                interact with FieldNext.
                <br />
                <br />
                For simplicity, all such technologies are defined as "Trackers" within
                this document – unless there is a reason to differentiate.
                <br />
                For example, while Cookies can be used on both web and mobile browsers,
                it would be inaccurate to talk about Cookies in the context of mobile
                apps as they are a browser-based Tracker. For this reason, within this
                document, the term Cookies is only used where it is specifically meant
                to indicate that particular type of Tracker.
                <br />
                <br />
                Some of the purposes for which Trackers are used may also require the
                User's consent. Whenever consent is given, it can be freely withdrawn at
                any time following the instructions provided in this document.
                <br />
                <br />
                FieldNext uses Trackers managed directly by us (so-called “first-party”
                Trackers) and Trackers that enable services provided by a third-party
                (so-called “third-party” Trackers). Unless otherwise specified within
                this document, third-party providers may access the Trackers managed by
                them.
                <br />
                The validity and expiration periods of Cookies and other similar
                Trackers may vary depending on the lifetime set by the FieldNext or the
                relevant provider. Some of them expire upon termination of the User’s
                browsing session.
                <br />
              </p>
              <p>
                <h4>
                  Activities strictly necessary for the operation of FieldNext and
                  delivery of the Service
                </h4>
                FieldNext uses so-called “technical” Cookies and other similar Trackers
                to carry out activities that are strictly necessary for the operation or
                delivery of the Service.
              </p>
            </p>
            <p>
              <h3>Additional information about Data collection and processing</h3>
              <p>
                <h4>Legal action</h4>
                The User's Personal Data may be used for legal purposes by us in Court
                or in the stages leading to possible legal action arising from improper
                use of FieldNext or the related Services. The User declares to be aware
                that we may be required to reveal personal data upon request of public
                authorities.
                <h4>Additional information about User's Personal Data</h4>
                In addition to the information contained in this privacy policy,
                FieldNext may provide the User with additional and contextual
                information concerning particular Services or the collection and
                processing of Personal Data upon request.
                <h4>System logs and maintenance</h4>
                For operation and maintenance purposes, FieldNext and any third-party
                services may collect files that record interaction with FieldNext
                (System logs) use other Personal Data (such as the IP Address) for this
                purpose.
                <h4>Information not contained in this policy</h4>
                More details concerning the collection or processing of Personal Data
                may be requested from FieldNext at any time. Please see the contact
                information at the bottom of this document.
                <h4>How “Do Not Track” requests are handled</h4>
                FieldNext does not support “Do Not Track” requests.
                <h4>Changes to this privacy policy</h4>
                The FieldNext reserves the right to make changes to this privacy policy
                at any time. It is strongly recommended to Users check this page often,
                referring to the date of the last modification listed at the bottom.
              </p>
            </p>
            <p>
              <h3>Contact us</h3>
              <p>
                If you have any questions or comments about this Privacy Policy, our
                Services, or your experience with our Website, web Console and Mobile
                App – contact us at contact@fieldnext.io
              </p>
            </p>
            <p>Last updated: September 07, 2021</p>
          </Typography>
        </Col>
      </Col>
    </Content>
  );
}
